import { ref, watchEffect } from "vue";
import type { SchedulerService } from "~/types/SchedulerService";
import { fetchService } from "~/utils/schedulerServices.service";

export function useSchedulerServices(serviceId: number) {
  const service = ref<SchedulerService | null>(null);
  const pending = ref(true);
  const cancel = ref<(() => void) | undefined>(undefined);

  watchEffect(() => {
    service.value = null;
    pending.value = true;

    fetchService(serviceId)
      .then((serviceResponse) => {
        if (serviceResponse === null) {
          return;
        }

        cancel.value = serviceResponse.cancel;

        const services = serviceResponse.data.services;

        if (services.length < 1 || services.length > 1) {
          return;
        }

        service.value = services[0];
      })
      .finally(() => {
        pending.value = false;
      });
  });

  return {
    cancel,
    pending,
    service,
  };
}
